import { gql } from '@apollo/client/core';

export const PRODUCTS_QUERY_LIST = gql`
  query ProductsQueryList($site_id: [Int!]!) {
    products(where: { site_id: { _in: $site_id } }) {
      id
      client_product_id
      label
      site_id
      fabrication_order_products {
        fabrication_order {
          id
          start
          end
          quantity
          area {
            name
            id
            unit {
              symbol
            }
          }
          fabrication_order_epis {
            epi
            cost_by_quantity
            cost
            consumption_kwh
            id
            meter_id
          }
        }
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query ProductsQuery($id: Int!) {
    products_by_pk(id: $id) {
      id
      client_product_id
      label
      site_id
      epi_standard
      fabrication_order_products {
        fabrication_order {
          id
          start
          end
          quantity
          client_fabrication_order_id
          area {
            name
            id
            unit {
              symbol
            }
          }
          fabrication_order_epis {
            epi
            cost_by_quantity
            cost
            consumption_kwh
            id
            meter_id
          }
        }
      }
    }
  }
`;

export const PRODUCT_MUTATION_UPDATE = gql`
  mutation ProductUpdateOne($id: Int!, $object: products_set_input!) {
    update_products(where: { id: { _eq: $id } }, _set: $object) {
      affected_rows
    }
  }
`;
